/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import IntercomContext from "../IntercomContext"
import IntercomChat from "../../components/IntercomChat/IntercomChat"
import { rawCheckWindowSize, windowSizes } from "../../utils/responsive"

const modifyIntercomVisibility = ({ setVisible = true }) => {
  if (document && document.body) {
    const bodyChildNodes = document.body.childNodes
    for (const childNode of bodyChildNodes) {
      if (childNode.tagName === "IFRAME") {
        if (childNode.className.includes("Intercom_Intercom")) {
          childNode.style.display = setVisible ? "block" : "none"
          break
        }
      }
    }
  }
}

const IntercomProvider = ({ children }) => {
  const [externalOpenIntercom, setExternalOpenIntercom] = useState(false)
  const [intercomShouldLoad, setIntercomShouldLoad] = useState(false)

  const setPathForIntercom = (path) => {
    // only load the intercom chat if the user navigates to a page that is not the home page
    // after intercom was loaded it should remain loaded.
    if (path !== "/") {
      setIntercomShouldLoad(true)
    }

    //hide the intercom chat during checkout on mobile
    if (
      path.startsWith("/checkout") &&
      rawCheckWindowSize({ maxWidth: windowSizes.tablet, defaultRender: true })
    ) {
      modifyIntercomVisibility({ setVisible: false })
    } else {
      modifyIntercomVisibility({ setVisible: true })
    }
  }

  return (
    <IntercomContext.Provider
      value={{
        externalOpenIntercom,
        setExternalOpenIntercom,
        setPathForIntercom,
      }}
    >
      <IntercomChat
        externalOpenIntercom={externalOpenIntercom}
        setExternalOpenIntercom={setExternalOpenIntercom}
        shouldLoad={intercomShouldLoad}
      />
      {children}
    </IntercomContext.Provider>
  )
}

export default IntercomProvider
