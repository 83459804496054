/* eslint-disable no-use-before-define */
import React, { useState, useContext } from "react"
import { navigate } from "gatsby"

import AuthContext from "."
import PushNotificationContext from "../PushNotification/PushNotificationContext"

import {
  logout,
  isLoggedIn,
  userLogin,
  userRegister,
} from "../../services/auth"
import { clearGuestCartTracking } from "../../lib/woocommApi"
import { gtagRegister } from "../../services/GoogleAnalytics"
import { pixelCompleteRegistration } from "../../services/FacebookPixel"

const AuthProvider = ({ children }) => {
  const [loggedInState, setLoggedInState] = useState(null)

  const { pushNotificationEndpoint } = useContext(PushNotificationContext)

  // Much better login checker!
  const checkLoggedInState = () => {
    setLoggedInState(isLoggedIn())
  }

  // Just a "register" wrapper. This should actually also perform login
  const performRegister = (
    email,
    password,
    first_name,
    last_name,
    bday,
    opt_in = false,
    captchaResponse = "",
    setLoading = () => {},
    setApiError = () => {},
    afterCallback = () => {}
  ) => {
    const callback = () => {
      gtagRegister()
      pixelCompleteRegistration()
      performLogin(email, password, setLoading, setApiError, afterCallback)
    }
    userRegister(
      email,
      password,
      first_name,
      last_name,
      bday,
      opt_in,
      captchaResponse,
      setLoading,
      setApiError,
      callback
    )
  }

  // Just a "userLogin" wrapper that also updates the global state
  const performLogin = (
    email,
    password,
    setLoading = () => {},
    setApiError = () => {},
    afterCallback = () => {}
  ) => {
    const callback = async (responseData) => {
      await afterCallback()
      setLoggedInState(true)
      if (pushNotificationEndpoint) {
        clearGuestCartTracking(pushNotificationEndpoint).catch((error) => {
          console.error("error while clearing guest cart data", error)
        })
      }
    }
    return userLogin(email, password, setLoading, setApiError, callback)
  }

  // Perform a logout, update the global logged in state and navigate away
  const performLogout = (navigateLink = null) => {
    logout()
    setLoggedInState(false)
    if (navigateLink !== null) {
      navigate(navigateLink)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        loggedInState,
        checkLoggedInState,
        performRegister,
        performLogin,
        performLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
