import React from "react"
import AuthProvider from "../context/AuthContext/AuthProvider"
import BranchProvider from "../context/BranchContext/BranchProvider"
import CartProvider from "../context/CartContext/CartProvider"
import FlatlistProvider from "../context/FlatlistContext/FlatlistProvider"
import IntercomProvider from "../context/IntercomContext/IntercomProvider"
import ProductProvider from "../context/ProductContext/ProductProvider"
import PushNotificationProvider from "../context/PushNotification/PushNotificationProvider"
import { isInStoreFrontend } from "../services/auth"

const RootElementWrapper = ({ element }) => {
  return (
    <PushNotificationProvider>
      <AuthProvider>
        <BranchProvider>
          <CartProvider>
            <IntercomProvider>
              <ProductProvider>
                {isInStoreFrontend() ? (
                  <FlatlistProvider>{element}</FlatlistProvider>
                ) : (
                  element
                )}
              </ProductProvider>
            </IntercomProvider>
          </CartProvider>
        </BranchProvider>
      </AuthProvider>
    </PushNotificationProvider>
  )
}

export default RootElementWrapper
