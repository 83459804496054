const isBrowser = () => typeof window !== "undefined"
const facebookCategory = "Home > Bedding"

// FACEBOOK PIXEL --- Select Product Event
export const pixelSelect = (item) => {
  try {
    // Prepare data
    let id_array = []
    if (item.tracking_id && item.tracking_id != 0) {
      id_array = Array.isArray(item.tracking_id)
        ? item.tracking_id
        : [item.tracking_id]
    }
    if (item.variation_id && item.variation_id != 0) {
      id_array = Array.isArray(item.variation_id)
        ? item.variation_id
        : [item.variation_id]
    }
    if (!id_array.length && item.product_id && item.product_id != 0) {
      id_array = Array.isArray(item.product_id)
        ? item.product_id
        : [item.product_id]
    }
    if (!id_array.length && item.wordpress_id && item.wordpress_id != 0) {
      id_array = Array.isArray(item.wordpress_id)
        ? item.wordpress_id
        : [item.wordpress_id]
    }
    if (!id_array.length && item.id && item.id != 0) {
      id_array = Array.isArray(item.id) ? item.id : [item.id]
    }
    id_array = id_array ? id_array : []

    // Send data
    const payload = {
      content_type: "product",
      content_ids: id_array,
      content_name: item.name,
      content_category: facebookCategory,
      value: item.price,
      currency: "ZAR",
    }
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log("event pixelSelect = payload = ", payload)
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "ViewContent", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Select Product Event".'
      )
  }
}

// FACEBOOK PIXEL --- Add Items To Cart Event
export const pixelAddToCart = (item) => {
  try {
    // Prepare data
    let id_array = []
    if (item.tracking_id && item.tracking_id != 0) {
      id_array = Array.isArray(item.tracking_id)
        ? item.tracking_id
        : [item.tracking_id]
    }
    if (item.variation_id && item.variation_id != 0) {
      id_array = Array.isArray(item.variation_id)
        ? item.variation_id
        : [item.variation_id]
    }
    if (!id_array.length && item.product_id && item.product_id != 0) {
      id_array = Array.isArray(item.product_id)
        ? item.product_id
        : [item.product_id]
    }
    if (!id_array.length && item.wordpress_id && item.wordpress_id != 0) {
      id_array = Array.isArray(item.wordpress_id)
        ? item.wordpress_id
        : [item.wordpress_id]
    }
    if (!id_array.length && item.id && item.id != 0) {
      id_array = Array.isArray(item.id) ? item.id : [item.id]
    }
    id_array = id_array ? id_array : []

    // Send data
    const payload = {
      content_type: "product",
      content_ids: id_array,
      content_name: item.name
        ? item.name
        : `${item.product_title}${item.size && ` - ${item.size}`}`,
      value: item.price ? item.price : item.unit_price,
      currency: "ZAR",
    }
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log("pixelAddToCart: payload = ", payload)
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "AddToCart", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Add Items To Cart Event".'
      )
  }
}

// FACEBOOK PIXEL --- Complete Registration event
export const pixelAddPaymentInfo = (paymentData) => {
  try {
    const payload = {
      content_type: "product",
      content_category: facebookCategory,
      content_ids: paymentData.line_items.map(
        (it) => it?.tracking_id || it.variation_id || it.product_id
      ),
      value: paymentData.total,
      currency: "ZAR",
    }
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log("event pixelAddPaymentInfo: payload = ", payload)
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "AddPaymentInfo", payload)
    }
  } catch (e) {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function Add Payment Info Event".'
      )
  }
}

// FACEBOOK PIXEL --- Begin Checkout
export const pixelBeginCheckout = (data) => {
  try {
    // Prepare data
    let items = data
      .map((it) => (it?.bundled_items ? null : it))
      .filter((i) => !!i)
    let content_ids = items.map(
      (it) => it?.tracking_id || it?.variation_id || it?.product_id
    )

    // Send data
    const payload = {
      content_type: "product",
      num_items: contents.reduce((tot, curr) => tot + curr.quantity, 0),
      value: data.reduce(
        (tot, curr) => (curr?.bundled_by ? tot : tot + curr.line_total),
        0
      ),
      content_category: facebookCategory,
      content_ids,
      currency: "ZAR",
    }
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log("event pixelBeginCheckout: payload = ", payload)
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "InitiateCheckout", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Begin Checkout".'
      )
  }
}

// FACEBOOK PIXEL --- Purchase Event
export const pixelPurchase = (paymentData) => {
  try {
    const payload = {
      content_type: "product",
      content_category: facebookCategory,
      content_ids: paymentData.line_items.map(
        (it) => it?.tracking_id || it.variation_id || it.product_id
      ),
      value: paymentData.total,
      currency: "ZAR",
    }
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log("event pixelPurchase: payload = ", payload)
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "Purchase", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Purchase Event".'
      )
  }
}

// FACEBOOK PIXEL --- Complete Registration event
export const pixelCompleteRegistration = () => {
  try {
    const payload = {
      contents: "Register",
      status: true,
      currency: "ZAR",
    }
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log("event CompleteRegistration: payload = ", payload)
    if (isBrowser() && window.fbq != null) {
      window.fbq("track", "CompleteRegistration", payload)
    }
  } catch {
    process.env.GATSBY_PIXEL_DEBUG == "true" &&
      console.log(
        'pixel event: Could not run Facebook Pixel function "Complete Registration Event".'
      )
  }
}

// // FACEBOOK PIXEL --- Login Event
// export const pixelLogin = () => {
//   try {
//     if (isBrowser() && window.fbq != null) {
//       process.env.GATSBY_PIXEL_DEBUG == "true" &&
//         console.log("pixel event Login [trackCustom]: no payload")
//       window.fbq("trackCustom", "Login")
//     }
//   } catch {
//     process.env.GATSBY_PIXEL_DEBUG == "true" &&
//       console.log(
//         'pixel event: Could not run Facebook Pixel function "Login Event".'
//       )
//   }
// }

// // FACEBOOK PIXEL --- Logout Event
// export const pixelLogout = () => {
//   try {
//     if (isBrowser() && window.fbq != null) {
//       process.env.GATSBY_PIXEL_DEBUG == "true" &&
//         console.log("pixel event Logout [trackCustom]: no payload")
//       window.fbq("trackCustom", "Logout")
//     }
//   } catch {
//     process.env.GATSBY_PIXEL_DEBUG == "true" &&
//       console.log(
//         'pixel event: Could not run Facebook Pixel function "Logout Event".'
//       )
//   }
// }

// // FACEBOOK PIXEL --- Update Account Event
// export const pixelUpdateAccount = () => {
//   try {
//     if (isBrowser() && window.fbq != null) {
//       process.env.GATSBY_PIXEL_DEBUG == "true" &&
//         console.log("pixel event UpdateAccount [trackCustom]: no payload")
//       window.fbq("trackCustom", "UpdateAccount")
//     }
//   } catch {
//     process.env.GATSBY_PIXEL_DEBUG == "true" &&
//       console.log(
//         'pixel event: Could not run Facebook Pixel function "Update Account Event".'
//       )
//   }
// }

// // FACEBOOK PIXEL --- Contact Us event
// export const pixelContactUs = () => {
//   try {
//     if (isBrowser() && window.fbq != null) {
//       process.env.GATSBY_PIXEL_DEBUG == "true" &&
//         console.log("pixel event Contact: no payload")
//       window.fbq("track", "Contact")
//     }
//   } catch {
//     process.env.GATSBY_PIXEL_DEBUG == "true" &&
//       console.log(
//         'pixel event: Could not run Facebook Pixel function "Contact Us Event".'
//       )
//   }
// }

// // FACEBOOK PIXEL --- Click event
// export const pixelClick = (text) => {
//   try {
//     if (isBrowser() && window.fbq != null) {
//       const payload = {
//         clickText: text,
//       }
//       process.env.GATSBY_PIXEL_DEBUG == "true" &&
//         console.log("pixel event ClickEvent: payload = ", payload)
//       window.fbq("trackCustom", "ClickEvent", payload)
//     }
//   } catch {
//     process.env.GATSBY_PIXEL_DEBUG == "true" &&
//       console.log(
//         'pixel event: Could not run Facebook Pixel function "Click Event".'
//       )
//   }
// }
