/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import BranchContext from "."

const BranchProvider = ({ children }) => {
  const graphqlData = useStaticQuery(ALL_BRANCHES)
  const branchList = graphqlData.allLocalWpGraphQlBranches.nodes

  const [validBranches, setValidBranches] = useState([])
  const [idToBranchMap, setIdToBranchMap] = useState({})
  const [branchSelectPopupOpen, setBranchSelectPopupOpen] = useState(false)
  const [selectedBranchObject, setSelectedBranchObject] = useState(null)
  const [selectedBranch, setSelectedBranchAbstract] = useState(null)

  useEffect(() => {
    const validBranches_temp = []
    const idToBranchMap_temp = {}
    for (let i = 0; i < branchList.length; ++i) {
      const branch_details = branchList[i]?.branch_details
      if (branch_details && "branchMoneyworksId" in branch_details) {
        const id = branch_details?.branchMoneyworksId
        if (id && typeof id == "string") {
          validBranches_temp.push(id)
          idToBranchMap_temp[id] = branch_details
        }
      }
    }
    setValidBranches(validBranches_temp)
    setIdToBranchMap(idToBranchMap_temp)
  }, [])

  // setSelectedBranch requires validBranches to be set, so this useEffect
  // runs after validBranches has been updated
  useEffect(() => {
    if (validBranches && typeof window != "undefined") {
      setSelectedBranch(window.localStorage.getItem("selectedBranch"))
    }
  }, [validBranches])

  const setSelectedBranch = (new_branch) => {
    if (typeof window != "undefined") {
      if (new_branch) {
        if (validBranches?.includes?.(new_branch)) {
          window.localStorage.setItem("selectedBranch", new_branch)
          setSelectedBranchAbstract(new_branch)
          if (idToBranchMap && new_branch in idToBranchMap) {
            setSelectedBranchObject(idToBranchMap[new_branch])
          } else {
            setSelectedBranchObject(null)
          }
        }
      }
    }
  }

  return (
    <BranchContext.Provider
      value={{
        selectedBranch,
        setSelectedBranch,
        selectedBranchObject,
        branchSelectPopupOpen,
        setBranchSelectPopupOpen,
        branchList,
        idToBranchMap,
      }}
    >
      {children}
    </BranchContext.Provider>
  )
}

export default BranchProvider

const ALL_BRANCHES = graphql`
  {
    allLocalWpGraphQlBranches {
      nodes {
        branch_details {
          branchName
          address
          operatingHours
          latitude
          longitude
          branchMoneyworksId
          image {
            imageFile {
              localFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
