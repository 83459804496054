/* eslint-disable no-use-before-define */
import React, { useContext, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ProductContext from "."
import CartContext from "../CartContext"
import { extractSku } from "../../lib/util"

const ProductProvider = ({ children }) => {
  const graphqlData = useStaticQuery(ALLPRODUCTS)
  const allProducts = graphqlData.allWcProducts.nodes
  const feverTreeInstalments = graphqlData.allFeverTree.nodes
  const stockByLocation = graphqlData.allLocalWpGraphQlStockByLocation.nodes
  const { cartContents = [] } = useContext(CartContext)
  const [currentProduct, setCurrentProduct] = useState(null)

  const stockByLocationMap = {}
  for (let node_i = 0; node_i < stockByLocation.length; ++node_i) {
    const stock = stockByLocation[node_i]
    stockByLocationMap[stock.sku] = stock
  }
  for (let node_i = 0; node_i < allProducts.length; ++node_i) {
    const prod = allProducts[node_i]
    const prod_vari_array = prod?.product_variations
    const bundle_array = prod?.bundled_items
    const prod_sku = extractSku(prod.sku)
    if (prod_sku && prod_sku in stockByLocationMap) {
      prod["stock_by_location"] = stockByLocationMap[prod_sku]
    }
    if (Array.isArray(prod_vari_array) && prod_vari_array.length) {
      for (let vari_i = 0; vari_i < prod_vari_array.length; ++vari_i) {
        const vari = prod_vari_array[vari_i]
        const sku = extractSku(vari.sku)
        if (sku && sku in stockByLocationMap) {
          vari["stock_by_location"] = stockByLocationMap[sku]
          // save changes made on vari
          prod_vari_array[vari_i] = vari
        }
      }
      // save changes made on prod_vari_array
      prod.product_variations = prod_vari_array
    }
    if (Array.isArray(bundle_array) && bundle_array.length) {
      for (let bundle_i = 0; bundle_i < bundle_array.length; ++bundle_i) {
        const bundle_vari_array = bundle_array[bundle_i]?.product_variations
        if (Array.isArray(bundle_vari_array) && bundle_vari_array.length) {
          for (let vari_i = 0; vari_i < bundle_vari_array.length; ++vari_i) {
            const vari = bundle_vari_array[vari_i]
            const sku = extractSku(vari.sku)
            if (sku && sku in stockByLocationMap) {
              vari["stock_by_location"] = stockByLocationMap[sku]
              // save changes made on vari
              bundle_vari_array[vari_i] = vari
            }
          }
          // save changes made on bundle_vari_array
          bundle_array[bundle_i].product_variations = bundle_vari_array
        }
      }
      // save changes made on bundle_array
      prod.bundled_items = bundle_array
    }
    // save changes to allProducts
    allProducts[node_i] = prod
  }

  // Get a certain size given a product
  const getSize = () => {}

  // Find the cart contents as products
  const cartContentsProducts = allProducts.filter((pr) =>
    cartContents?.some((it) => it.product_id == pr.wordpress_id)
  )

  // Filter products that should appear on the cart drawer
  const notCartCategories = ["Beds", "Bed Bases", "Mattresses"]
  const cartRelatedProducts = allProducts.filter(
    (pr) =>
      !pr.categories.some((ct) => notCartCategories.includes(ct.name)) &&
      pr.acf.cart_products === "Yes"
  )

  // Filter products that should appear on the Best Deals page
  const bestDealsCategories = ["Beds", "Bedroom Furniture", "Mattresses"]
  const bestDealsProducts = allProducts.filter((pr) =>
    pr.categories.some((ct) => bestDealsCategories.includes(ct.name))
  )

  // Filter products that should appear on the Compare drawer and Compare page
  const compareCategories = ["Beds", "Mattresses"]
  const compareProducts = allProducts.filter((pr) =>
    pr.categories.some((ct) => compareCategories.includes(ct.name))
  )

  // Filter products that should appear on the Bed Finder
  const bedFinderCategories = ["Beds", "Mattresses"]
  const bedFinderProducts = allProducts.filter((pr) =>
    pr.categories.some((ct) => bedFinderCategories.includes(ct.name))
  )

  // Filter products that should appear on the Home page
  const featuredProducts = allProducts.filter((pr) => pr.featured)

  return (
    <ProductContext.Provider
      value={{
        allProducts,
        feverTreeInstalments,
        cartRelatedProducts,
        cartContentsProducts,
        bestDealsProducts,
        compareProducts,
        bedFinderProducts,
        featuredProducts,
        getSize,
        currentProduct,
        setCurrentProduct,
        stockByLocation,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export default ProductProvider

const ALLPRODUCTS = graphql`
  {
    allFeverTree {
      nodes {
        instalment
        price
      }
    }
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
      }
    ) {
      nodes {
        ...ProductInformation
      }
    }
    allLocalWpGraphQlStockByLocation {
      nodes {
        sku
        bel {
          effective
          soh
        }
        bok {
          effective
          soh
        }
        blo {
          effective
          soh
        }
        cap {
          effective
          soh
        }
        dur {
          effective
          soh
        }
        mid {
          effective
          soh
        }
        nel {
          effective
          soh
        }
        pol {
          effective
          soh
        }
        por {
          effective
          soh
        }
        pre {
          effective
          soh
        }
        ran {
          effective
          soh
        }
      }
    }
  }
  fragment ProductInformation on wcProducts {
    id
    objectID: wordpress_id
    wordpress_id
    name
    slug
    sku
    price
    regular_price
    manage_stock
    stock_status
    stock_quantity
    backorders_allowed
    average_rating
    rating_count
    featured
    shipping_class
    categories {
      name
      slug
    }
    attributes {
      name
      options
    }
    images {
      src
      localFile {
        childImageSharp {
          fixed(width: 250) {
            src
          }
        }
      }
    }
    acf {
      id
      bedroom_type
      fabric_type
      guarantee
      high_resilience_foam
      latex_foam
      limited_movement_transfer
      mattress_in_box
      mattress_type
      memory_foam
      product_list_priority
      sizes
      turn__no_turn_mattress
      warrantee
      weight_capacity
      zero_movement_transfer
      comfort_trial_available
      comfort_level
      featured_variations
      bed_bundle_size_images {
        bundle_images {
          image {
            localFile {
              childImageSharp {
                fixed(width: 250) {
                  src
                }
              }
            }
          }
          product_image
          product_image_sku
        }
      }
    }
    product_variations {
      id
      sku
      stock_quantity
      stock_status
      manage_stock
      backorders_allowed
      price
      regular_price
      shipping_class
      attributes {
        name
        option
      }
      image {
        localFile {
          childImageSharp {
            fixed(width: 250) {
              src
            }
          }
        }
      }
    }
    bundled_items {
      name
      wordpress_id
      id
      slug
      sku
      price
      regular_price
      stock_quantity
      backorders_allowed
      shipping_class
      categories {
        name
        slug
      }
      product_variations {
        id
        sku
        manage_stock
        stock_status
        shipping_class
        stock_quantity
        backorders_allowed
        price
        regular_price
        attributes {
          name
          option
        }
      }
    }
  }
`
