/* eslint-disable no-use-before-define */
import React, { useState, useLayoutEffect, useContext } from "react"
import CartContext from "."
import AuthContext from "../AuthContext"
import PushNotificationContext from "../PushNotification/PushNotificationContext"
import { getUser } from "../../services/auth"
import {
  getCartRequest,
  postCartItemRequest,
  deleteCartItemRequest,
  updateCartItemRequest,
  clearCartRequest,
  createOrder,
  getOrder,
} from "../../lib/woocommApi"
import {
  gtagRemoveFromCart,
  gtagUpdateCart,
  gtagAddToCart,
} from "../../services/GoogleAnalytics"
import { pixelAddToCart } from "../../services/FacebookPixel"

const CartProvider = ({ children }) => {
  const [cartHash, setCartHash] = useState(null)
  const [cartCount, setCartCount] = useState(null)
  const [cartContents, setCartContents] = useState(null)
  const [cartMeta, setCartMeta] = useState(null)
  const [cartShipping, setCartShipping] = useState(null)
  const [cartReady, setCartReady] = useState(true)
  const [cartState, setCartState] = useState("cart")
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false)
  const [compareDrawer, setCompareDrawerOpen] = useState(false)
  const { checkLoggedInState, performLogout } = useContext(AuthContext)
  const { pushNotificationEndpoint } = useContext(PushNotificationContext)

  const addToCart = async (postCartItemStruct) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()

    // #### Debug messages ####
    const bundleMessage =
      postCartItemStruct.bundle_id && `bundle ${postCartItemStruct.bundle_id}`
    const variationMessage =
      postCartItemStruct.variation_id &&
      `variation ${postCartItemStruct.variation_id}`
    const productMessage =
      postCartItemStruct.product_id &&
      `product ${postCartItemStruct.product_id}`
    const message = bundleMessage || variationMessage || productMessage
    console.log(`Adding to cart: ${postCartItemStruct.quantity}x ${message}`)
    // #### Debug messages ####

    // add custom data to cart
    if (!("cart_item_data" in postCartItemStruct)) {
      postCartItemStruct.cart_item_data = {}
    }
    postCartItemStruct.cart_item_data.pushNotificationEndpoint = pushNotificationEndpoint
    return postCartItemRequest(postCartItemStruct, user.token, performLogout)
      .then(async (postCartItemResponse) => {
        const cartContents =
          postCartItemResponse.cart &&
          postCartItemResponse.cart.data &&
          Object.values(postCartItemResponse.cart.data)
        //get newly added product from cart contents
        let newlyAddedProduct = false
        for (const lineItem of cartContents) {
          if (lineItem && !lineItem.bundled_by) {
            if (postCartItemStruct.bundle_id) {
              if (lineItem.bundled_items) {
                if (postCartItemStruct.bundle_id == lineItem.product_id) {
                  newlyAddedProduct = lineItem
                }
              }
            } else if (postCartItemStruct.variation_id) {
              if (postCartItemStruct.variation_id == lineItem.variation_id) {
                newlyAddedProduct = lineItem
              }
            } else if (postCartItemStruct.product_id == lineItem.product_id) {
              newlyAddedProduct = lineItem
            }
          }
          if (newlyAddedProduct) {
            break
          }
        }

        //fire add to cart analytics for the product that was just added
        if (newlyAddedProduct) {
          gtagAddToCart(newlyAddedProduct, postCartItemStruct.quantity)
          pixelAddToCart(newlyAddedProduct)
        } else {
          console.log(
            "Analytics error. Couldn't find product to send in addToCart analytics"
          )
        }

        setCart(postCartItemResponse)
      })
      .catch((e) => {
        setCartReady(true)
        throw e
      })
  }

  const removeFromCart = async (product) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()
    return deleteCartItemRequest(
      product,
      user.token,
      performLogout,
      pushNotificationEndpoint
    )
      .then(async (removeFromCartResponse) => {
        setCart(removeFromCartResponse)
        gtagRemoveFromCart(product, product.quantity)
        return removeFromCartResponse
      })
      .catch((e) => {
        setCartReady(true)
        throw e
      })
  }

  const updateInCart = async (product, quantity) => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()
    return updateCartItemRequest(
      product,
      quantity,
      user.token,
      performLogout,
      pushNotificationEndpoint
    )
      .then(async (updateInCartResponse) => {
        setCart(updateInCartResponse)
        const quantityChange = quantity - product.quantity
        if (quantityChange < 0) {
          gtagRemoveFromCart(product, Math.abs(quantityChange))
        } else {
          gtagAddToCart(product, quantityChange)
        }
        gtagUpdateCart(product, quantity)
      })
      .catch((e) => {
        setCartReady(true)
        throw e
      })
  }

  // Get all cart data
  const getCart = async () => {
    checkLoggedInState()
    setCartReady(false)
    const user = getUser()
    return getCartRequest(user, performLogout) // Get items in cart
      .then((getCartResponse) => {
        setCart(getCartResponse)
      })
      .catch((e) => {
        setCart({})
        setCartReady(true)
        throw e
      })
  }

  // Convert our cart into an order
  const createOrderFromCart = async (repCode, paymentCode) => {
    const user = getUser()
    try {
      // Create order from our cart
      const orderResponse = await createOrder(
        { rep_code: repCode, payment_code: paymentCode, ...cartMeta },
        user.token,
        performLogout
      )
      console.log("Created Order: ", orderResponse)
      if (orderResponse.id) {
        // analytics place order should be here
        return orderResponse
      } else {
        return {
          error:
            "Oh no! Something went wrong while trying to create your order. We are working on getting it fixed. Please try again or contact us for support",
        }
      }
    } catch (error) {
      console.log("Failed to create order")
      return {
        error:
          "Oh no! Something went wrong while trying to create your order. We are working on getting it fixed. Please try again or contact us for support",
      }
    }
  }

  // Fetch order by Order ID and handle errors properly
  const getOrderById = async (orderId) => {
    const { token } = getUser()

    // Verify the order ID is valid
    try {
      const orderData = await getOrder(orderId, token, performLogout)

      // Setting order data will render the PeachPay component which will trigger the fetch of payment data
      if (orderData.id) {
        return orderData
      } else {
        return {
          error: "An error occured while fetching your order, order invalid.",
        }
      }
    } catch (e) {
      console.log("Failed to get order by ID")
      if (e && e.errors && e.errors[0] && e.errors[0].detail) {
        console.log(e.errors[0].detail)
        return { error: e.errors[0].detail }
      } else if (e.message) {
        return { error: e.message }
      } else {
        return {
          error: "An error occured while fetching your order.",
        }
      }
    }
  }

  // On first load, get the cart
  useLayoutEffect(() => {
    getCart()
  }, [])

  // Ensure current cart. When a user logs in, we need to make sure they don't lose their cart
  // because WooCommerce bombs out randomly
  const ensureCurrentCart = async () => {
    // If current cart is empty, just fetch cart and return
    if (!cartContents || cartContents.length === 0) {
      await getCart()
      return
    }

    // Current cart not empty. Let's ensure backend cart is identical to current cart
    setCartReady(false)
    const user = getUser()
    const { token } = user
    try {
      const getCartResponse = await getCartRequest(user, performLogout)
      // Destruct data and verify status codes
      let newCartContents =
        getCartResponse.cart &&
        getCartResponse.cart.data &&
        Object.values(getCartResponse.cart.data)
      let newCartMeta = getCartResponse.meta && getCartResponse.meta.data
      if (getCartResponse.cart && getCartResponse.cart.status !== 200) {
        newCartContents = false
      }
      if (getCartResponse.meta && getCartResponse.meta.status !== 200) {
        newCartMeta = false
      }

      let cartIsIdentical = true

      // Check if the hash changed
      if (didCartHashChange(newCartContents, cartHash)) {
        cartIsIdentical = false
      }

      // If the hash did not change, check if the contents changed (backup check)
      if (cartIsIdentical) {
        if (
          didCartChange(newCartContents, cartContents, newCartMeta, cartMeta)
        ) {
          cartIsIdentical = false
        }
      }

      // If the carts are identical, we are safe to keep it
      if (cartIsIdentical) {
        console.log("ensureCurrentCart does not need to run")
        setCartReady(true)
        return
      } else {
        // Backend cart is not the same as front end cart. We have to add the items again
        console.log("ensureCurrentCart needs to run")
        // If backend cart isn't valid, or is valid but not empty, clear it
        if (!newCartContents || newCartContents.length !== 0) {
          await clearCartRequest({ token, pushNotificationEndpoint })
        }
        // Add old cart items one by one
        for (let old_ind = 0; old_ind < cartContents.length; old_ind++) {
          // For children of bundles, just skip them
          if (cartContents[old_ind].bundled_by) {
            continue
          }
          // For parent of bundles, search the cart for the children and add them
          if (cartContents[old_ind].bundled_items) {
            let variation_items = []
            for (
              let check_ind = 0;
              check_ind < cartContents.length;
              check_ind++
            ) {
              if (
                cartContents[old_ind].key == cartContents[check_ind].bundled_by
              ) {
                variation_items.push({
                  id: cartContents[check_ind].variation_id,
                  quantity: cartContents[check_ind].quantity,
                })
              }
            }

            // So this is a horrible hack fix for the case where multiple bundle items are added to the cart
            // with a special exception for when it is a King mattress with 2x Single bases
            // This is needed because we already specify the quantity in the bundle data but still need to
            // 2x product ids for single bases when a king is added
            variation_items = variation_items.map((item) => ({
              id: item.id,
              quantity: parseInt(
                item.quantity /
                  variation_items.reduce((a, b) =>
                    Math.min(a.quantity, b.quantity)
                  )
              ),
            }))
            let variation_array = []
            variation_items.forEach((it) => {
              for (let i = 0; i < it.quantity; i++) {
                variation_array.push(it.id)
              }
            })

            const bundle_data = {
              variation_id: variation_array,
              bundle_id: cartContents[old_ind].product_id,
              quantity: cartContents[old_ind].quantity,
            }
            const postResponse = await postCartItemRequest(
              bundle_data,
              user.token
            )
          } else {
            // Add simple/variable product
            const id_type =
              cartContents[old_ind].variation_id > 0
                ? "variation_id"
                : "product_id"
            const id_value =
              cartContents[old_ind].variation_id > 0
                ? cartContents[old_ind].variation_id
                : cartContents[old_ind].product_id
            const product_data = {
              [id_type]: id_value,
              quantity: cartContents[old_ind].quantity,
            }
            const postResponse = await postCartItemRequest(
              product_data,
              user.token
            )
          }
        }
      }
    } catch (e) {
      console.log("Failed to ensureCurrentCart")
      console.log(e)
    }

    // Now fetch the cart and replace the internal state
    await getCart()
  }

  // Update our cart including contents, meta and shipping
  const setCart = (response) => {
    // Destruct data
    let newCartContents =
      response.cart && response.cart.data && Object.values(response.cart.data)
    let newCartMeta = response.meta && response.meta.data
    let newCartShipping = response.shipping && response.shipping.data

    // Verify responses are successful
    if (response.cart && response.cart.status !== 200) {
      newCartContents = false
    }
    if (response.meta && response.meta.status !== 200) {
      newCartMeta = false
    }
    if (response.shipping && response.shipping.status !== 200) {
      newCartShipping = false
    }

    // Only update if the cart hash changed or the cart is made empty. Saves us cart refreshes
    const newCartHash = didCartHashChange(newCartContents, cartHash)

    // Cart hash has changed, update cart and hash
    if (newCartHash) {
      setCartHash(newCartHash)
      setCartContents(
        newCartContents.sort((a, b) => b.line_total - a.line_total)
      )
      // Update cart-count for icon
      const total = newCartContents.reduce((tot, num) => {
        // We do not want bundled products to be counted
        if (num.bundled_by) {
          return tot
        } else {
          return tot + num.quantity
        }
      }, 0)
      updateCartCount(total)
    }

    if (newCartMeta) {
      setCartMetaAndAddVat(newCartMeta)
    }
    if (newCartShipping) {
      setCartShipping(newCartShipping)
    }

    setCartReady(true)
  }

  // Whe we set the cart totals, calculate vat incl
  const setCartMetaAndAddVat = (data) => {
    if (data && data.cart_contents_total && data.cart_contents_tax) {
      data.cart_contents_total =
        parseFloat(data.cart_contents_total) +
        parseFloat(data.cart_contents_tax)
    }
    if (data && data.subtotal && data.subtotal_tax) {
      data.subtotal = parseFloat(data.subtotal) + parseFloat(data.subtotal_tax)
    }
    setCartMeta(data)
  }

  // Update cart count if it changed
  const updateCartCount = (newCartCount) => {
    if (newCartCount !== cartCount) {
      // When we have orderid specified in the URL, don't mess with cart states
      if (window && !window.location.search.includes("orderid")) {
        setCartState("cart")
      }
      setCartCount(newCartCount)
    }
  }

  return (
    <CartContext.Provider
      value={{
        cartCount,
        cartState,
        setCartState,
        getCart,
        addToCart,
        updateInCart,
        removeFromCart,
        ensureCurrentCart,
        cartContents,
        cartMeta,
        cartShipping,
        cartReady,
        cartDrawerOpen,
        setCartDrawerOpen,
        createOrderFromCart,
        getOrderById,
        compareDrawer,
        setCompareDrawerOpen,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider

// This function tries to check if the user is on cart page
// It helps us to reduce numerous cart-related calls when they are not necessary
const isOnCheckoutPage = () => {
  if (
    typeof window !== "undefined" &&
    window.location.pathname &&
    !window.location.pathname.includes("/checkout/")
  ) {
    return false
  }

  return true
}

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

// Compare a cart's hash to one stored in the state
const didCartHashChange = (cart, oldHash) => {
  let newCartHash = -1
  if (cart && cart[0] && cart[0].cart_hash) {
    newCartHash = cart[0].cart_hash
  } else if (cart && cart.length === 0) {
    newCartHash = 1
  }

  if (newCartHash !== -1 && newCartHash !== oldHash) {
    return newCartHash
  }
  return false
}

// Compare the current cart's content to the new cart
const didCartChange = (newCart, oldCart, newCartMeta, oldCartMeta) => {
  // Compare current cart and new cart total to determine if our cart is the same
  if (newCartMeta) {
    if (newCartMeta.total !== oldCartMeta.total) {
      return true
    }
  } else {
    return true
  }
  // Compare current cart and new cart contents to determine if our cart is the same
  if (newCart && newCart.length === oldCart.length) {
    for (let c_ind = 0; c_ind < newCart.length; c_ind++) {
      if (
        !oldCart.some(
          (it) =>
            it.key === newCart[c_ind].key &&
            it.quantity === newCart[c_ind].quantity
        )
      ) {
        return true
      }
    }
  } else {
    return true
  }
}
