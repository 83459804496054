import React, { useState, useEffect, useLayoutEffect, useContext } from "react"
import AuthContext from "../../context/AuthContext"
import IntercomContext from "../../context/IntercomContext"
import { getUser } from "../../services/auth"
import { rawCheckWindowSize, windowSizes } from "../../utils/responsive"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const LoadableIntercom = loadable(
  (props) => props.shouldLoad && pMinDelay(import("intercom-react"), 20000)
)

const IntercomChat = ({
  shouldLoad = false,
  externalOpenIntercom = false,
  setExternalOpenIntercom = false,
}) => {
  const { loggedInState } = useContext(AuthContext)
  const [isMobileState, setIsMobileState] = useState(undefined)
  const [internalOpenIntercom, setInternalOpenIntercom] = useState(
    externalOpenIntercom
  )
  const [intercomSettingsState, setIntercomSettingsState] = useState({})

  if (
    setExternalOpenIntercom !== false &&
    typeof setExternalOpenIntercom !== "function"
  ) {
    console.error(`IntercomChat component was used incorrectly. 
    setExternalOpenIntercom should either be a function or false`)
  }

  // This function is used to update the intercom open state.
  // If there is an external open state to manage then the setExternalOpenIntercom function is called
  // If there is no external open state to manage, then only the setInternalOpenIntercom function is called
  const changeIntercomOpenState = (intercomOpen) => {
    if (typeof setExternalOpenIntercom === "function") {
      setExternalOpenIntercom(intercomOpen)
    } else {
      setInternalOpenIntercom(intercomOpen)
    }
  }

  // This useLayoutEffect is used to monitor for screen resizes
  // and to initialise the states relevant to screen size
  useLayoutEffect(() => {
    function windowResizeEventHandler() {
      setIsMobileState(
        rawCheckWindowSize({
          maxWidth: windowSizes.tablet,
          defaultRender: true,
        })
      )
    }
    if (typeof window != "undefined") {
      window.addEventListener("resize", windowResizeEventHandler)
      setIsMobileState(
        rawCheckWindowSize({
          maxWidth: windowSizes.tablet,
          defaultRender: true,
        })
      )
    }
    fixIntercomIframeMobileStyle(!externalOpenIntercom)
    if (typeof window != "undefined") {
      return () =>
        typeof window != "undefined" &&
        window.removeEventListener("resize", windowResizeEventHandler)
    }
  }, [])

  // This useEffect is used to monitor if the intercom chat is opened and on a
  // mobile screen to fix the vertical position if open on mobile
  useEffect(() => {
    if (typeof setExternalOpenIntercom === "function") {
      fixIntercomIframeMobileStyle(!externalOpenIntercom)
    } else {
      fixIntercomIframeMobileStyle(!internalOpenIntercom)
    }
  }, [externalOpenIntercom, internalOpenIntercom, isMobileState])

  // Search through the top level document and body tags for the intercom iframe
  // set the style of said iframe to move 40px up if the chat is closed on mobile,
  // otherwise the iframe should be moved back down to the bottom of the screen
  const fixIntercomIframeMobileStyle = (mobileClosed = false) => {
    if (document && document.body) {
      const bodyChildNodes = document.body.childNodes
      for (const childNode of bodyChildNodes) {
        if (childNode.tagName === "IFRAME") {
          if (childNode.className.includes("Intercom_Intercom")) {
            childNode.style.bottom = mobileClosed && isMobileState ? "40px" : 0
            break
          }
        }
      }
    }
  }

  // If the user logs in change the settings to pass the user details to intercom
  useEffect(() => {
    if (loggedInState) {
      let userInfo = getUser() //get the logged in user
      let intercomSettings = {}

      // get the logged in user's hash. Without the hash use intercom as if the user
      // hasn't been logged in
      if (userInfo.user_intercom_hash) {
        intercomSettings.user_hash = userInfo.user_intercom_hash
        if (userInfo.user_display_name) {
          intercomSettings.name = userInfo.user_display_name
        } else if (userInfo.name) {
          intercomSettings.name = userInfo.user_nicename
        }
        if (userInfo.user_email) {
          intercomSettings.email = userInfo.user_email
        }
        if (
          userInfo.tokenPayload &&
          userInfo.tokenPayload.data &&
          userInfo.tokenPayload.data.user &&
          userInfo.tokenPayload.data.user.id
        ) {
          intercomSettings.user_id = userInfo.tokenPayload.data.user.id
        }
      } else {
        intercomSettings = {}
      }

      // only update the intercomSettingsState if the settings should change
      if (
        JSON.stringify(intercomSettings) !=
        JSON.stringify(intercomSettingsState)
      ) {
        setIntercomSettingsState(intercomSettings)
      }
    }
  }, [loggedInState])

  // This function is called whenever the intercom chat window is opened.
  // Just sets the intercom open state to true.
  const onOpenHandler = () => {
    changeIntercomOpenState(true)
  }

  // This function is called whenever the intercom chat window is closed.
  // This function sets the intercom open state to false and calls the
  // setIntercomIframeBottomMobileClosed function after some time. The reason
  // that the timeout function is used is because the intercom iframe's style
  // is updated by some other process after the chat is closed.
  const onCloseHandler = () => {
    changeIntercomOpenState(false)
    // This is terrible but I couldn't get another way for the intercom bubble to remain off of the menu
    if (
      typeof window != "undefined" &&
      typeof HTMLIFrameElement != "undefined"
    ) {
      setTimeout(function () {
        fixIntercomIframeMobileStyle(true)
      }, 300)
      setTimeout(function () {
        fixIntercomIframeMobileStyle(true)
      }, 600)
    }
  }

  // This function is called whenever the intercom chat widget is initialised.
  // During initialisation we just want to make sure that the intercom open state
  // and iframe position have been initialised correctly.
  const onInitializationHandler = () => {
    changeIntercomOpenState(externalOpenIntercom)
    fixIntercomIframeMobileStyle(!externalOpenIntercom)
  }

  if (shouldLoad) {
    const { setPathForIntercom } = useContext(IntercomContext)
    if (typeof window != "undefined") {
      setTimeout(function () {
        setPathForIntercom(window.location.pathname)
      }, 600)
    }
    return (
      <LoadableIntercom
        shouldLoad={shouldLoad}
        fallback={null}
        open={externalOpenIntercom}
        onOpen={onOpenHandler}
        onClose={onCloseHandler}
        onInitialization={onInitializationHandler}
        appId={process.env.GATSBY_INTERCOM_APP_ID}
        user={intercomSettingsState}
      />
    )
  }
  return null
}

export default IntercomChat
