"use strict";

exports.onRouteUpdate = function (_ref, pluginOptions) {
  var location = _ref.location;

  // Don't track while developing.
  if (
    pluginOptions.enabled &&
    pluginOptions.enableViewContent &&
    typeof fbq === "function"
  ) {
    fbq("track", "ViewContent");
  }
};
