module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Mattress Warehouse","short_name":"TMW","start_url":"/","background_color":"#1a293c","theme_color":"#1a293c","display":"minimal-ui","icon":"src/images/FaviconBed.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4746b1b68b20ae177fe68af1ff10b2aa"},
    },{
      plugin: require('../plugins/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"175981796976796","enabled":false,"enableViewContent":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-78972663-2","AW-877216132"],"gtagConfig":{"currency":"ZAR"},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ED1C24","showSpinner":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
