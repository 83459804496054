import { useEffect, useRef } from "react"
import { isBrowser } from "../services/auth"
import { format } from "date-fns"
import { titleCase, replaceAll } from "voca"

const shippingInfo = {
  "delayed-shipping": {
    color: "#9ba5ae",
    backgroundColor: "#FCDBA2",
    textColor: "#DA8D08",
    delay: "10 - 15",
    text: "Delayed Shipping",
    description: "This product delays shipping to an estimated ",
  },
  "fast-track-shipping": {
    color: "#0988bb",
    backgroundColor: "#0998d2",
    textColor: "white",
    delay: "3 - 5",
    text: "Fast Shipping",
    description: "This product has fast shipping, estimated ",
  },
  default: {
    color: "#9ba5ae",
    backgroundColor: "#9ba5ae",
    textColor: "white",
    delay: "5 - 7",
    text: "Shipping",
    description: "This product will ship in an estimated ",
  },
}

// Order we typically want dropdowns to appear in
export const sizeOrder = [
  "Single",
  "Single XL",
  "Single and XL",
  "Single/Single XL",
  "Three Quarter",
  "Three Quarter XL",
  "Three Quarter and XL",
  "Three Quarter/Three Quarter XL",
  "Double",
  "Double XL",
  "Double and XL",
  "Double/Double XL",
  "Queen",
  "Queen XL",
  "Queen and XL",
  "Queen/Queen XL",
  "King",
  "King XL",
  "King and XL",
  "King/King XL",
  "Super King",
]

export const shippingClassGet = (slug = "default", type) => {
  if (shippingInfo[slug]) {
    return shippingInfo[slug][type]
  }
  return shippingInfo["default"][type]
}

export const lookupShippingClass = (soh, eff) => {
  if (soh > 1 && eff > 1) {
    return "fast-track-shipping"
  } else if (eff < 1) {
    return "delayed-shipping"
  }
  return ""
}

/*
 * determineShippingClass:

 * fallback_shipping_class is used if the shipping class can't be derived for the location

 * stock_by_location_array will only have 1 item for simple and variable products
 * stock_by_location_array will have 2 items for bundled products
 * stock_by_location_array = [{
 *  effective: <int>
 *  soh: <int>
 * }]
 
 * selectedBranch is a string containing the selected branch
 */
export const determineShippingClass = (
  fallback_shipping_class,
  stock_by_location_array,
  selectedBranch
) => {
  if (!Array.isArray(stock_by_location_array)) {
    stock_by_location_array = [stock_by_location_array]
  }
  if (selectedBranch) {
    let soh = null
    let eff = null
    let result_valid = true
    for (const stock_by_location of stock_by_location_array) {
      if (
        !selectedBranch ||
        !stock_by_location ||
        !(selectedBranch in stock_by_location)
      ) {
        result_valid = false
        return
      } else {
        const stock = stock_by_location[selectedBranch]
        if (!stock || !("soh" in stock) || !("effective" in stock)) {
          result_valid = false
          return
        } else {
          soh = soh === null ? stock.soh : Math.min(soh, stock.soh)
          eff = eff === null ? stock.effective : Math.min(eff, stock.effective)
        }
      }
    }
    if (result_valid) {
      return lookupShippingClass(soh, eff)
    }
  }
  return fallback_shipping_class ?? null
}

export const getShippingClassInCart = (cartContents, item, selectedBranch) => {
  let shipping_class = false

  // If the item is a bundle, find the mattress and use that shipping class instead
  if (item.bundled_items) {
    // Find the cart items that are in this bundle
    const stock_by_location_array = []
    let mattress_shipping_class = null
    for (const ch_item of cartContents) {
      if (item.bundled_items.includes(ch_item.key)) {
        stock_by_location_array.push(ch_item?.stock_by_location)
        // Check if the item is a mattress and has a shipping_class
        if (
          ch_item.categories.some((cat) => cat.slug == "mattresses") &&
          ch_item.shipping_class
        ) {
          mattress_shipping_class = ch_item?.shipping_class?.slug
        }
      }
    }
    shipping_class = determineShippingClass(
      mattress_shipping_class,
      stock_by_location_array,
      selectedBranch
    )
  } else {
    shipping_class = determineShippingClass(
      item?.shipping_class?.slug,
      [item?.stock_by_location],
      selectedBranch
    )
  }

  return shipping_class
}

const skuRegex = /(TMW\d+)/gi
export const extractSku = (sku) => {
  if (typeof sku == "string") {
    const capture = sku.match(skuRegex)
    if (Array.isArray(capture)) {
      return capture[0]
    }
    return capture
  }
  return null
}

const buildCrumbs = (product = {}, pathname = "") => {
  let categories = []
  if (product && product.categories && product.categories.length > 0) {
    categories = product.categories
  }
  categories = categories.filter((ct) => ct.slug !== "uncategorised") // don't use this category ever

  // Main first-crumb categories to consider
  const bedroomFurnitureCategory = categories.find(
    (ct) => ct.slug == "bedroom-furniture"
  )
  const bedroomAccessoriesCategory = categories.find(
    (ct) => ct.slug == "bedroom-accessories"
  )
  const beddingCategory = categories.find((ct) => ct.slug == "bedding")
  const allBrandsCategory = categories.find((ct) => ct.slug == "all-brands")

  // Other categories to consider (for naming conventions)
  const mattressCategory = categories.find((ct) => ct.slug == "mattresses")
  const bedsCategory = categories.find((ct) => ct.slug == "beds")

  // All remaining categories that are top level
  const remainingCategories = categories.filter(
    (ct) =>
      ct.slug !== "bedroom-furniture" &&
      ct.slug !== "bedroom-accessories" &&
      ct.slug !== "bedding" &&
      ct.slug !== "mattresses" &&
      ct.slug !== "beds" &&
      ct.slug !== "all-brands" &&
      ct.wordpress_parent_id === 0
  )

  // Crumb storage
  let firstCrumb = null
  let secondCrumb = null

  // Build bedding crumbs
  if (!firstCrumb && beddingCategory) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == beddingCategory.wordpress_id
    )
    if (childCat) {
      firstCrumb = beddingCategory
      secondCrumb = childCat
    }
  }
  // Build Bedroom Furniture crumbs
  if (!firstCrumb && bedroomFurnitureCategory) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == bedroomFurnitureCategory.wordpress_id
    )
    if (childCat) {
      firstCrumb = bedroomFurnitureCategory
      secondCrumb = childCat
    }
  }
  // Build Bedroom Accessories crumbs
  if (!firstCrumb && bedroomAccessoriesCategory) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == bedroomAccessoriesCategory.wordpress_id
    )
    if (childCat) {
      firstCrumb = bedroomAccessoriesCategory
      secondCrumb = childCat
    }
  }
  // Build All Brands crumbs
  if (!firstCrumb && allBrandsCategory) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == allBrandsCategory.wordpress_id
    )
    if (childCat) {
      if (mattressCategory) {
        firstCrumb = allBrandsCategory
        secondCrumb = {
          name: `${childCat.name} Mattresses`,
          slug: `${childCat.slug}-mattresses`,
        }
      } else if (bedsCategory) {
        firstCrumb = allBrandsCategory
        secondCrumb = {
          name: `${childCat.name} Beds`,
          slug: `${childCat.slug}-beds`,
        }
      } else {
        firstCrumb = allBrandsCategory
        secondCrumb = childCat
      }
    }
  }
  if (!firstCrumb && remainingCategories.length > 0) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == remainingCategories[0].wordpress_id
    )
    firstCrumb = remainingCategories[0]
    if (childCat) {
      secondCrumb = childCat
    }
  }

  // Build product crumb if categories are present
  if (product && product.wordpress_id) {
    let returnCrumbs = []
    if (firstCrumb) {
      returnCrumbs.push(firstCrumb)
      if (secondCrumb) {
        returnCrumbs.push(secondCrumb)
      }
    }

    return formatCrumbs(returnCrumbs)
  }

  // If we don't have any product category crumbs, built a breadcrumb using the URL path
  let pathCrumbs = []
  try {
    pathCrumbs = pathname
      .split("/")
      .filter((i) => !!i && i !== "category")
      .map((it, i) => {
        return {
          name: it,
          slug: it,
        }
      })
    // eslint-disable-next-line no-empty
  } catch {}
  return formatCrumbs(pathCrumbs)
}
const formatCrumbs = (array) => {
  let pathStore = "/"
  const returnCrumbs =
    array &&
    array.length > 0 &&
    array.map((cr) => {
      const name = titleCase(replaceAll(cr.name, "-", " "))
      const retVal = {
        name,
        link: `${pathStore}${cr.slug}/`,
      }
      pathStore = retVal.link
      return retVal
    })
  if (returnCrumbs && returnCrumbs.length > 0) {
    return returnCrumbs
  }
  return [
    {
      name: "Shop",
      link: `/shop/`,
    },
  ]
}

const addSizeFormat = (size) => {
  switch (size) {
    case "single-extra-length":
      return "single-xl"
    case "three-quarter-extra-length":
      return "three-quarter-xl"
    case "double-extra-length":
      return "double-xl"
    case "queen-extra-length":
      return "queen-xl"
    case "king-extra-length":
      return "king-xl"
    case "single-xl":
      return "single-extra-length"
    case "three-quarter-xl":
      return "three-quarter-extra-length"
    case "double-xl":
      return "double-extra-length"
    case "queen-xl":
      return "queen-extra-length"
    case "king-xl":
      return "king-extra-length"
  }
  return null
}

const doQueryTest = (queryTest, callback) => {
  try {
    if (window.location.search.endsWith(queryTest)) {
      const removeQueryTestStrLen =
        window.location.search.length - queryTest.length
      history.replaceState(
        null,
        "",
        window.location.search.substring(0, removeQueryTestStrLen)
      )
      callback()
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

const formatPrice = (number) => {
  const parsedNumber = parseFloat(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  if ("0.00" != parsedNumber && "NaN" != parsedNumber) {
    return `R ${parsedNumber}`
  }
  return false
}

const timeStampedLog = (message) => {
  console.log(
    `${
      new Date().toISOString().match(/(\d\d:\d\d:\d\d\.\d\d\d\w)/)[0]
    } > ${message}`
  )
}
// Parse URL query values -----------------------------------------------------
const getQueryVariable = (variable, optCallback) => {
  if (typeof window !== "undefined") {
    const currentQuery = new URLSearchParams(window.location.search)
    const value = currentQuery.get(variable)
    if (value) {
      if (optCallback) optCallback(value)
      return value
    }
  }
  if (optCallback) optCallback(false)
  return false
}
const setQueryVariable = (variable, value) => {
  if (typeof window !== "undefined") {
    const currentQuery = new URLSearchParams(window.location.search)
    currentQuery.set(variable, value)
    window.location.search = currentQuery.toString()
  }
  return true
}

// partially applies arguments to function and returns a function waiting for the rest of the arguments
const curry = (f, arr = []) => (...args) =>
  ((a) => (a.length === f.length ? f(...a) : curry(f, a)))([...arr, ...args])

const getPath = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
// safly acces path on object
const path = curry(getPath)

const getBackgroundFluid = path([
  "backgroundImage",
  "imageFile",
  "childImageSharp",
  "fluid",
])

const formatMoney = (number) =>
  "R " +
  parseFloat(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")

const formatDateStr = (dateStr) =>
  isBrowser() ? format(new window.Date(dateStr), "MM LLL yy") : ""

// Create nice slugs from complex strings
const slugify = (string) => {
  const a =
    "àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;"
  const b =
    "aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  try {
    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w-]+/g, "") // Remove all non-word characters
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "") // Trim - from end of text
    // eslint-disable-next-line no-empty
  } catch {}
}

// Format float to currncy format (commas seperating thousands and 2 decimals)
const formatCurrencyInt = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const getFluid = (node) =>
  path(["featuredImage", "imageFile", "childImageSharp", "fluid"], node)

const fluid = (node) => path(["imageFile", "childImageSharp", "fluid"], node)

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
// Strip out our WP link to create a local path link
function CreateLocalLink(url) {
  if (url === "#") {
    return null
  }
  let newUri = url.replace(process.env.GATSBY_WP_URL, "")
  return newUri
}

const formatPhoneToLink = (str) => {
  if (!str.includes("(")) {
    return `<a href="tel:${str.replace(/ /g, "")}">${str}</a>`
  }
  const [number, label] = str.split("(")
  const labelWithSpan = "<span class='subtle'>(" + label + "</span>"
  const numberWithLink = `<a href="tel:${number.replace(
    / /g,
    ""
  )}">${number}</a>`
  return numberWithLink + labelWithSpan
}

const formatEmailToLink = (str) => `<a href="mailto:${str}">${str}</a>`

// prettier-ignore
const shorten = str => str.split(" ").slice(0, 30).join(" ").concat("...")
const isValid = (str) => str && str.length > 0
const stripHtml = (html) => html.replace(/(<([^>]+)>)/gi, "")
const stripAndShorten = (str) => shorten(stripHtml(str))

const createExcerpt = (excerpt, fallbackContent) =>
  stripAndShorten(
    isValid(excerpt) ? excerpt : isValid(fallbackContent) ? fallbackContent : ""
  )

const getIndexOfKey = (data, key) => {
  var i
  if (data && data.length) {
    for (i = 0; i < data.length; i++) {
      if (data[i].key == key) {
        return i
      }
    }
  }
  return null
}

/*
 *  This function calculates the approximate distance between 2 pins
 *  The results are an approximation of the distance in km
 *  The result is calculated by taking the coordinates as 2 dimensional points
 *  and calculating the pythagorean distance between them
 *
 * The result is multiplied by 120 as fudge factor
 *
 *  pin1 = {
 *    latitude,
 *    longitude
 *  }
 *
 *  pin2 = {
 *    latitude,
 *    longitude
 *  }
 *
 *  Return null on error
 */
export const calcCoordDistanceApprox = (pin1, pin2) => {
  const lat1 = parseFloat(pin1?.latitude)
  const lng1 = parseFloat(pin1?.longitude)
  const lat2 = parseFloat(pin2?.latitude)
  const lng2 = parseFloat(pin2?.longitude)
  if (!isNaN(lat1) && !isNaN(lng1) && !isNaN(lat2) && !isNaN(lng2)) {
    const latDist = Math.pow(lat1 - lat2, 2)
    const lngDist = Math.pow(lng1 - lng2, 2)
    return Math.round(Math.sqrt(latDist + lngDist) * 120)
  }
  return Infinity
}

export {
  createExcerpt,
  path,
  curry,
  getBackgroundFluid,
  useInterval,
  slugify,
  getFluid,
  CreateLocalLink,
  formatPhoneToLink,
  formatEmailToLink,
  formatDateStr,
  fluid,
  formatCurrencyInt,
  formatMoney,
  getQueryVariable,
  setQueryVariable,
  getIndexOfKey,
  timeStampedLog,
  formatPrice,
  doQueryTest,
  buildCrumbs,
  addSizeFormat,
}
