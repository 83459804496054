/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { isInStoreFrontend } from "../../services/auth"
import FlatlistContext from "."

const FlatlistProvider = ({ children }) => {
  let graphqlData = {}
  if (isInStoreFrontend()) {
    graphqlData = useStaticQuery(ALLPRODUCTS)
  }
  const [flatlistProducts, setFlatlistProducts] = useState(
    (graphqlData.allProductFlatlist && graphqlData.allProductFlatlist.nodes) ||
      []
  )

  return (
    <FlatlistContext.Provider
      value={{
        flatlistProducts,
      }}
    >
      {children}
    </FlatlistContext.Provider>
  )
}

export default FlatlistProvider

const ALLPRODUCTS = graphql`
  {
    allProductFlatlist {
      nodes {
        databaseId
        price
        sku
        size
        title
      }
    }
  }
`
