import React from "react"

const ProductContext = React.createContext({
  allProducts: [],
  cartRelatedProducts: [],
  bestDealsProducts: [],
  bedFinderProducts: [],
  featuredProducts: [],
  getSize: () => {},
  currentProduct: null,
  setCurrentProduct: () => {},
  stockByLocation: null,
})

export default ProductContext
