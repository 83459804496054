import { titleCase, replaceAll } from "voca"
const isBrowser = () => typeof window !== "undefined"

const deSlugify = (word) => {
  return titleCase(replaceAll(word, "-", " "))
}

// GOOGLE ANALYTICS --- Select Product Event
export const gtagSelect = (item) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemStruct = {
        id: item.sku,
        name: item.name,
        price: item.price,
        category: item.category_names.join("/"),
      }
      const eventParams = {
        event_category: "Product",
        event_label: itemStruct.name,
        content_type: "product",
        items: [itemStruct],
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log("gtag event: view_item -> eventParams = ", eventParams)
      window.gtag("event", "view_item", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch (e) {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Select Product Event".',
        e
      )
  }
}

// GOOGLE ANALYTICS --- Add Items To Cart Event
export const gtagAddToCart = (item, quantity) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = {
        id: item.tracking_sku ? item.tracking_sku : item.sku,
        name: item.name
          ? item.name
          : `${item.product_title}${
              item.size ? ` - ${deSlugify(item.size)}` : ""
            }`,
        quantity,
        price: item.price ? item.price : item.unit_price,
      }
      item.categories &&
        (itemsStruct.category = item.categories
          .map((cat) => cat.name)
          .join("/"))
      const eventParams = {
        event_category: "Cart",
        event_label: itemsStruct.name,
        content_type: "product",
        items: [itemsStruct],
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log("gtag event: add_to_cart -> eventParams = ", eventParams)
      window.gtag("event", "add_to_cart", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Add Items To Cart Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Remove Items From Cart Event
export const gtagRemoveFromCart = (item, quantity) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = [
        {
          id: item.tracking_sku,
          name: `${item.product_title}${item.size && ` - ${item.size}`}`,
          quantity,
          price: item.unit_price,
          category: item.categories.map((cat) => cat.name).join("/"),
        },
      ]
      const eventParams = {
        event_category: "Cart",
        event_label: item.name,
        content_type: "product",
        items: itemsStruct,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: remove_from_cart -> eventParams = ",
          eventParams
        )
      window.gtag("event", "remove_from_cart", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Remove Items From Cart Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Update Items In Cart Event
export const gtagUpdateCart = (item, quantity) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = [
        {
          id: item.tracking_sku,
          name: `${item.product_title}${
            item.size ? ` - ${deSlugify(item.size)}` : ""
          }`,
          quantity,
          price: item.unit_price,
          category: item.categories.map((cat) => cat.name).join("/"),
        },
      ]
      const eventParams = {
        event_category: "Cart",
        event_label: itemsStruct.name,
        content_type: "product",
        items: itemsStruct,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: update_cart [custom] -> eventParams = ",
          eventParams
        )
      window.gtag("event", "update_cart", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Update Items In Cart Event".'
      )
  }
}

// GOOGLE ANALYTICS --- View Cart
export const gtagViewCart = (data) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = data
        .filter((it) => {
          return !("bundled_by" in it)
        })
        .map((it) => {
          return {
            id: it.tracking_sku,
            name: `${it.product_title}${
              it.size ? ` - ${deSlugify(it.size)}` : ""
            }`,
            quantity: it.quantity,
            price: it.unit_price,
            category: it.categories.map((cat) => cat.name).join("/"),
          }
        })
      const eventParams = {
        event_category: "Cart",
        event_label: "Viewed Cart",
        items: itemsStruct,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: view_cart [custom] -> eventParams = ",
          eventParams
        )
      window.gtag("event", "view_cart", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "View Cart".'
      )
  }
}

// GOOGLE ANALYTICS --- Begin checkout
export const gtagBeginCheckout = (data) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = data
        .filter((it) => {
          return !("bundled_by" in it)
        })
        .map((it) => ({
          id: it.tracking_sku,
          name: `${it.product_title}${
            it.size ? ` - ${deSlugify(it.size)}` : ""
          }`,
          quantity: it.quantity,
          price: it.unit_price,
          category: it.categories.map((cat) => cat.name).join("/"),
        }))
      const eventParams = {
        event_category: "Checkout",
        event_label: "Begin Checkout",
        content_type: "product",
        items: itemsStruct,
        checkout_option: "Begin Checkout",
        checkout_step: 1,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log("gtag event: begin_checkout -> eventParams = ", eventParams)
      window.gtag("event", "begin_checkout", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Go To Cart".'
      )
  }
}

// GOOGLE ANALYTICS --- Checkout Step - Delivery information
export const gtagCheckoutDelivery = (data) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = data
        .filter((it) => {
          return !("bundled_by" in it)
        })
        .map((it) => ({
          id: it.tracking_sku,
          name: `${it.product_title}${
            it.size ? ` - ${deSlugify(it.size)}` : ""
          }`,
          quantity: it.quantity,
          price: it.unit_price,
          category: it.categories.map((cat) => cat.name).join("/"),
        }))
      const eventParams = {
        event_category: "Checkout",
        event_label: "Provide Delivery Information",
        content_type: "product",
        items: itemsStruct,
        checkout_option: "Provide Delivery Information",
        checkout_step: 2,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: checkout_progress 2 (Provide Delivery Information) -> eventParams = ",
          eventParams
        )
      window.gtag("event", "checkout_progress", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Checkout Step = Delivery Information".'
      )
  }
}

// GOOGLE ANALYTICS --- Checkout Step - Payment
export const gtagCheckoutViewPaymentOptions = (data) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = data.map((it) => ({
        id: it.tracking_sku,
        name: `${it.product_title}${it.size ? ` - ${deSlugify(it.size)}` : ""}`,
        quantity: it.quantity,
        price: it.unit_price,
        category: it.categories.map((cat) => cat.name).join("/"),
      }))
      const eventParams = {
        event_category: "Checkout",
        event_label: "View Payment Methods",
        content_type: "product",
        items: itemsStruct,
        checkout_option: "View Payment Methods",
        checkout_step: 3,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: checkout_progress 3 (View Payment Methods) -> eventParams = ",
          eventParams
        )
      window.gtag("event", "checkout_progress", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Checkout Step - Payment".'
      )
  }
}

// GOOGLE ANALYTICS --- Payment method credit card
export const gtagSelectPaymentMethod = (paymentOption) => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: "Checkout",
        event_label: "Select Payment Option - " + paymentOption,
        checkout_step: 3,
        checkout_option: paymentOption,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: set_checkout_option 3 (Select Payment Option) -> eventParams = ",
          eventParams
        )
      window.gtag("event", "set_checkout_option", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Payment Method Credit Card".'
      )
  }
}

// GOOGLE ANALYTICS --- Payment method credit card
export const gtagPayNowOnOldOrder = (orderNumber) => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: "Checkout",
        event_label: "Old Order: Pay Now - " + orderNumber,
        checkout_step: 3,
        checkout_option: "pay_old_order",
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: set_checkout_option 3 (Old Order: Pay Now) -> eventParams = ",
          eventParams
        )
      window.gtag("event", "set_checkout_option", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Payment Method Credit Card".'
      )
  }
}

// GOOGLE ANALYTICS --- Checkout Step - Delivery information
export const gtagPlaceOrder = (data) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = data
        .filter((it) => {
          return !("bundled_by" in it)
        })
        .map((it) => ({
          id: it.tracking_sku,
          name: `${it.product_title}${
            it.size ? ` - ${deSlugify(it.size)}` : ""
          }`,
          quantity: it.quantity,
          price: it.unit_price,
          category: it.categories.map((cat) => cat.name).join("/"),
        }))
      const eventParams = {
        event_category: "Checkout",
        event_label: "Place Order",
        content_type: "product",
        items: itemsStruct,
        checkout_option: "Place Order",
        checkout_step: 4,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: checkout_progress 4 (Place Order) -> eventParams = ",
          eventParams
        )
      window.gtag("event", "checkout_progress", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Checkout Step = Delivery Information".'
      )
  }
}

// GOOGLE ANALYTICS --- Purchase Event
export const gtagPurchase = (paymentData) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = paymentData.line_items.map((item, i) => ({
        id: item.sku,
        name: `${item.title}${item.size ? ` - ${deSlugify(item.size)}` : ""}`,
        quantity: item.quantity,
        price: item.unit_price,
        category: item.categories.map((cat) => cat.name).join("/"),
      }))
      const eventParams_purchase = {
        event_category: "Checkout",
        event_label: "Complete Purchase",
        transaction_id: paymentData.id,
        affiliation: "The Mattress Warehouse",
        value: paymentData.total,
        items: itemsStruct,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: purchase -> eventParams = ",
          eventParams_purchase
        )
      window.gtag("event", "purchase", eventParams_purchase)
      const eventParams_conversion = {
        send_to: `${process.env.GATSBY_GTAG_ACCOUNT_ID}/aPoVCOKWs4YBEISDpaID`,
        value: paymentData.total,
        currency: "ZAR",
        transaction_id: paymentData.id,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: conversion -> eventParams = ",
          eventParams_conversion
        )
      window.gtag("event", "conversion", eventParams_conversion)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Purchase Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Register Event
export const gtagRegister = () => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: "Account",
        event_label: "Register",
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log("gtag event: sign_up -> eventParams = ", eventParams)
      window.gtag("event", "sign_up", eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        'gtag event: Could not run Google Analytics function "Register Event".'
      )
  }
}

// GOOGLE ANALYTICS --- Login Event
export const gtagLogin = () =>
  emitCustomEvent({
    event_category: "Account",
    event_name: "login",
    event_label: "Login",
  })

// GOOGLE ANALYTICS --- Logout Event
export const gtagLogout = () =>
  emitCustomEvent({
    event_category: "Account",
    event_name: "logout",
    event_label: "Logout",
  })

// GOOGLE ANALYTICS --- Update Account Event
export const gtagUpdateAccount = () =>
  emitCustomEvent({
    event_category: "Account",
    event_name: "update_account",
    event_label: "Update account information",
  })

/*
 * function: emitCustomEvent
 * send a custom GTAG event
 *
 *  event_name (Required): Name of the event in Google Analytics Dashboard
 *  event_category (Optional): Name of a group of events in Google Analytics Dashboard
 *  event_label (Optional): Longer description of the event in Google Analytics Dashboard
 */
const emitCustomEvent = ({ event_name, event_category, event_label }) => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category,
        event_label,
      }
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          `gtag event: ${event_name} [custom] -> eventParams = `,
          eventParams
        )
      window.gtag("event", event_name, eventParams)
    } else {
      process.env.GATSBY_GTAG_DEBUG == "true" &&
        console.log(
          "gtag event: Could not run Google Analytics function, gtag not initialised"
        )
    }
  } catch {
    process.env.GATSBY_GTAG_DEBUG == "true" &&
      console.log(
        `gtag event: Could not run Google Analytics function "${event_name}".`
      )
  }
}
