import React, { useState, useLayoutEffect } from "react"
import styled from "styled-components"
const isBrowser = typeof window !== "undefined"

// Constants
export const windowSizes = {
  tiny: 480,
  mobile: 768,
  mobileMedium: 960,
  tablet: 1025,
}

/*
 * Wondering HOW TO USE THESE?
 *
 * You can wrap your components in them as normal React Components (recommended where possible)
 * The "Not" versions work oppositely as an "else" render.
 * NOTE: This method works dynamically, eg when you resize window with mouse the render will update
 *
 * -> I only want MobileMenu to render on mobile devices (window width < 767px)
 *
 *       <Responsive maxWidth={windowSizes.mobile}>
 *         <MobileMenu />
 *       </Responsive>
 *
 * or use a custom value:
 *
 *       <Responsive maxWidth={768}>
 *         <MobileMenu />
 *       </Responsive>
 *
 * -> I only want MobileMenu to render on mobile devices (window width < 767px), else NormalMenu should render (window width >= 767px)
 *
 *       <Responsive maxWidth={windowSizes.mobile}>
 *         <MobileMenu />
 *       </Responsive>
 *       <Responsive minWidth={windowSizes.mobile}>
 *         <NormalMenu />
 *       </Responsive>
 *
 * Or, you use the function if you want to use logic to do something/style something
 * NOTE: This method will trigger rerenders for you, but only when the function return changes from previous render, so it's very optimised
 * -> I only want doSpecialMobileThing() to run on mobile devices
 * -> I only want "margin: 10px" applied to Button on mobile devices
 *
 *       const isOnMobile = checkWindowSize({ maxWidth: windowSizes.mobile })
 *       if (isOnMobile) {
 *         doSpecialMobileThing()
 *       }
 *       <Button style={isOnMobile ? "margin: 10px;" : "margin: 30px;"}/>
 *
 * Or, if you have to, use rawCheckWindowSize() when React gives you an error using the above version.
 * These functions are once-off and very basic, use only if you really have to
 *
 *       const isOnMobile = rawCheckWindowSize({ maxWidth: windowSizes.mobile })
 */

// Responsive component
export const Responsive = ({ children, minWidth, maxWidth }) => {
  const [windowWidth, setWindowWidth] = useState(0)
  useLayoutEffect(() => {
    if (!isBrowser) return
    const handleResize = () => {
      setWindowWidth(window.screen.width)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  let responsiveMediaQuery = ""
  if (minWidth) {
    responsiveMediaQuery = `max-width: ${minWidth - 1}px`
  } else if (maxWidth) {
    responsiveMediaQuery = `min-width: ${maxWidth + 1}px`
  } else {
    console.error(
      "Responsive component can't be used without either minWidth or maxWidth"
    )
    return null
  }

  const responsiveStyle = `
	  @media (${responsiveMediaQuery}) {
	  	display: none;
	  }
  `

  return (
    <StyledResponsive key={windowWidth} responsiveStyle={responsiveStyle}>
      {children}
    </StyledResponsive>
  )
}

const StyledResponsive = styled.div`
  ${({ responsiveStyle }) => responsiveStyle}
`

// Function for react-ful logic operations
export const checkWindowSize = ({
  minWidth,
  maxWidth,
  defaultRender = undefined,
}) => {
  const [shouldRender, setShouldRender] = useState(undefined)
  useLayoutEffect(() => {
    if (!isBrowser) return
    const handleResize = () => {
      if (minWidth) {
        setShouldRender(window.screen.width > minWidth ? true : false)
      } else if (maxWidth) {
        setShouldRender(window.screen.width <= maxWidth ? true : false)
      } else {
        console.log("checkWindowSize error: No min/max width supplied")
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Decide a render condtion based on window size
  if (shouldRender === true) {
    return true
  } else if (shouldRender === false) {
    return false
  }
  // Decide a render condtion based on fallback (initial value)
  if (defaultRender == true) {
    return true
  } else if (defaultRender === false) {
    return false
  }

  // "undefined" return means we don't know if the window size yet
  return undefined
}

// Function for raw logic operations, when React is not available. Much faster to check
export const rawCheckWindowSize = ({
  minWidth,
  maxWidth,
  defaultRender = undefined,
}) => {
  if (isBrowser) {
    if (minWidth) {
      return window.screen.width >= minWidth
    } else if (maxWidth) {
      return window.screen.width < maxWidth
    } else {
      console.log("rawCheckWindowSize error: No min/max width supplied")
    }
  }
  if (defaultRender == true) {
    return true
  } else if (defaultRender === false) {
    return false
  }

  // "undefined" return means we don't know if the window size yet
  return undefined
}
